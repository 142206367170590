<template>
  <v-sheet
    :class="classes"
    class="mb-5"
  >
    <h2
      class="display-2 font-weight-light secondary--text mb-4"
      v-text="value.title"
    />
    <v-layout mb-4>
      <div class="mr-3">
        <v-icon small>
          mdi-calendar
        </v-icon>
        {{ value.date }}
      </div>
      <div
        v-if="value.author !== ''"
        class="mr-3"
      >
        <v-icon
          small
          color="blue"
        >
          mdi-link
        </v-icon>
        <a
          :href="value.author"
          target="_blank"
          style="color:deepskyblue"
        >{{ value.author }}</a>
      </div>
    </v-layout>

    <v-layout
      v-if="value.image"
      row
      wrap
    >
      <v-flex
        xs12
        sm7
        px-1
      >
        <div
          class="subheading mb-5 post__body"
          v-html="value.body"
        />
      </v-flex>
      <v-flex
        xs12
        sm5
      >
        <a
          :href="require('../assets/'+value.image)"
          target="_blank"
        >
          <v-img
            contain
            :src="require('../assets/'+value.image)"
            style="margin: auto;border: 2px solid grey"
          />
        </a>
      </v-flex>
    </v-layout>

    <v-layout
      v-else
      row
      wrap
    >
      <div
        class="subheading mb-5 post__body"
        v-html="value.body"
      />
    </v-layout>

    <v-layout
      v-if="value.tags && value.tags.length > 0"
      mb-5
    >
      <v-icon class="mr-2">
        mdi-tag-multiple
      </v-icon>
      {{ value.tags.join(', ') }}
    </v-layout>
  </v-sheet>
</template>

<script>
  export default {
    props: {
      dense: {
        type: Boolean,
        default: false
      },
      value: {
        type: Object,
        default: () => ({})
      }
    },

    computed: {
      classes () {
        return {
          'post--dense': this.dense
        }
      }
    }
  }
</script>

<style>
  .post--dense .post__body > *:not(:first-child) {
    display: none;
  }
</style>
